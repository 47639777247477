.pdf-heading{
  background-color: #000000;
  display:flex ;
}

.pdfcontent{
  color: #fff;
  margin-top: 40px;
  margin-left: 90px;
  line-height: 50px;
  padding-right: 30px;
  margin-bottom: 30px;
}
.pdf-head{
  font-size: 50px;
}
.pdf-about{
  font-size: 20px;
}
.pdfbutton{
  width: 200px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f7c90;
  color: white;
  height: 50px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.pdfimg{
  margin-left: 130px;
  height: 200px;
}

@media only screen and (max-width:1024px) {
.pdfcontent{
  color: #fff;
  margin-top: 40px;
  margin-left: 0px;
  line-height: 30px;
  margin-bottom: 40px; 
}

.pdf-head{
  margin-left: 20px;
  font-weight: 900;
  font-size: 30px;
  line-height: 50px;
}
.pdf-about{
  font-size: 20px;
  margin-left: 20px;

}
.pdfbutton{
  width: 180px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  color: black;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
margin-left: 20px;
}
.pdfimg{
  display: none;
}

}



/* STYLING PRODUCT SECTION */

.products{
  background-color: rgb(241, 234, 234);
  overflow: hidden;
}
.products h4{
  display: none;
}
.products-items{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ebook{
  margin: 10px;
}
.ebook-image:hover{
  opacity: 0.5;
  color: #000000;
}
.ebook-image{
  height: 300px;
  width: 250px;
  cursor: pointer; 
  z-index: -1;
}
.ebook button{
  width: 250px;
  border-radius: 6px;
  height: 30px;
  background-color:rgb(245, 180, 14);
  border:rgb(245, 180, 14) ;
  cursor: pointer;
}


/* PRODUCT MEDIA QUERIES */
@media only screen and (max-width: 768px) {
  .products h4{
    display:contents;
  }
 
  .ebook-image{
    height: 200px;
    width: 160px;
  }

  .ebook button{
    width: 160px;
    border-radius: 6px;
    height: 30px;
    background-color:rgb(245, 180, 14);
    border:rgb(245, 180, 14) ;
    
  }

}