.footer {
    border-top: 1px solid #dcdacb;
    padding-top: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #fff;
  }
  
  .footer .upperDiv {
    width: 94%;
    position: relative;
  }
  
  .footer .upperDiv .linksContainer {
    display: flex;
    justify-content: space-between;
    left: 0px;
    width: 750px;
    line-height: 1.8rem;
    text-align: left;
  }
  
  .footer .upperDiv .linksDiv {
    width: 240px;
  }
  
  .footer .upperDiv p {
    cursor: pointer;
    color: #fff;
    font-size: 14px;
  }
  
  .footer .upperDiv p:hover {
    color: #094c59;
  }
  
  .footer .lowerDiv {
    width: 94%;
    height: 160px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  