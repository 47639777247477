
.HeadImage{
  width: 100%;
  height: 300px;
}



@media only screen and (max-width:1024px) {

  .HeadImage{
    width: 100%;
    height: 300%;
  }
}

@media only screen and (max-width:700px) {

  .HeadImage{
    width: 100%;
    height: 300%;
  }
}
 

/*styling freecourse section*/


/*BOX01*/
.course-box1{
  width: 305px;
  height:200px ;
  background-color: rgb(11, 125, 135);
  cursor: pointer;

}
.course-box1 h3{
font-size:12px;
font-weight:bold;
margin: 10px;
padding-top: 6px;
font-family:Arial, Helvetica, sans-serif;
}
.course-box1 h2{
  color: white;
  font-size: 85px;
  text-align: center;
  }
  .course-box1 p{
    color: white;
    font-weight: bold;
    position: absolute;
    top: 520px;
    left: 23px;
  }
.bta{
  display: flex;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
}
.bta .h4{
  margin-left: 190px;
}
  

.course-box1:hover{
  background-color: rgb(10, 93, 134);
}

/*BOX02*/
.course-box2{
  width: 305px;
  height:200px ;
  background-color: slateblue;
  cursor: pointer;
}
.course-box2 h3{
font-size:12px;
font-weight:bold;
margin: 10px;
padding-top: 6px;
font-family:Arial, Helvetica, sans-serif;
}
.course-box2 h2{
  color: white;
  font-size: 85px;
  text-align: center;
  }
  .course-box2 p{
    color: white;
    font-weight: bold;
    position: absolute;
    top: 520px;
    left: 370px;
  }
.bta{
  display: flex;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
}
.bta .h4{
  margin-left: 190px;
}
  

.course-box2:hover{
  background-color: skyblue;
}




/*BOX03*/
.course-box3{
  width: 305px;
  height:200px ;
  background-color: rgb(223, 230, 14);
  cursor: pointer;

}
.course-box3 h3{
font-size:12px;
font-weight:bold;
margin: 10px;
padding-top: 6px;
font-family:Arial, Helvetica, sans-serif;
}
.course-box3 h2{
  color: white;
  font-size: 85px;
  text-align: center;
  }
  .course-box3 p{
    color: white;
    font-weight: bold;
    position: absolute;
    top: 520px;
    left: 730px;
  }
.bta{
  display: flex;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
}
.bta .h4{
  margin-left: 190px;
}
  

.course-box3:hover{
  background-color: rgb(134, 113, 10);
}


/*BOX04*/
 .course-box4{
  width: 305px;
  height:200px ;
  background-color:steelblue;
  cursor: pointer;
}
.course-box4 h3{
font-size:12px;
font-weight:bold;
margin: 10px;
padding-top: 6px;
font-family:Arial, Helvetica, sans-serif;
}
.course-box4 h2{
  color: white;
  font-size: 35px;
  text-align: center;
  }
  .course-box4 p{
    color: white;
    font-weight: bold;
    position: absolute;
    top: 520px;
    left: 980px;
  }
.bta{
  display: flex;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
}
.bta .h4{
  margin-left: 190px;
}
  

.course-box4:hover{
  background-color:slateblue;
} 






.course-thumbmail{
  height: 200px;
  width: 305px;
}
.course-content{
  display: flex;
}
.content{
  margin: 5px;
}
@media only screen and (max-width: 768px) {

  .course-content{
    display: block;
  }

 
/*BOX01*/
.course-box1{
  width: 345px;
  height:200px ;
  background-color: rgb(11, 125, 135);
  cursor: pointer;

}
.course-box1 h3{
font-size:12px;
font-weight:bold;
margin: 10px;
padding-top: 6px;
font-family:Arial, Helvetica, sans-serif;
}
.course-box1 h2{
  color: white;
  font-size: 85px;
  text-align: center;
  }
  .course-box1 p{
    color: white;
    font-weight: bold;
    position: absolute;
    top: 320px;
    left: 45px;
  }
.bta{
  display: flex;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
}
.bta .h4{
  margin-left: 190px;
}
  

.course-box1:hover{
  background-color: rgb(10, 93, 134);
}
  
  /*BOX02*/
.course-box2{
  width: 345px;
  height:200px ;
  background-color: slateblue;
  cursor: pointer;
}
.course-box2 h3{
font-size:12px;
font-weight:bold;
margin: 10px;
padding-top: 6px;
font-family:Arial, Helvetica, sans-serif;
}
.course-box2 h2{
  color: white;
  font-size: 85px;
  text-align: center;
  }
  .course-box2 p{
    color: white;
    font-weight: bold;
    position: absolute;
    top: 670px;
    left: 80px;
  }
.bta{
  display: flex;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
}
.bta .h4{
  margin-left: 190px;
}
  

.course-box2:hover{
  background-color: skyblue;
}





/*BOX03*/
.course-box3{
  width: 345px;
  height:200px ;
  background-color: rgb(223, 230, 14);
  cursor: pointer;

}
.course-box3 h3{
font-size:12px;
font-weight:bold;
margin: 10px;
padding-top: 6px;
font-family:Arial, Helvetica, sans-serif;
}
.course-box3 h2{
  color: white;
  font-size: 85px;
  text-align: center;
  }
  .course-box3 p{
    color: white;
    font-weight: bold;
    position:absolute;
    top: 1015px;
    left: 125px;
  }
.bta{
  display: flex;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
}
.bta .h4{
  margin-left: 190px;
}
  

.course-box3:hover{
  background-color: rgb(134, 113, 10);
}



/*BOX04*/
.course-box4{
  width: 345px;
  height:200px ;
  background-color:steelblue;
  cursor: pointer;
}
.course-box4 h3{
font-size:12px;
font-weight:bold;
margin: 10px;
padding-top: 6px;
font-family:Arial, Helvetica, sans-serif;
}
.course-box4 h2{
  color: white;
  font-size: 35px;
  text-align: center;
  }
  .course-box4 p{
    color: white;
    font-weight: bold;
    position: absolute;
    top: 1360px;
    left: 50px;
  }
  .content a{
    font-weight: bold;
    text-decoration: none;
  }
.bta{
  display: flex;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
}
.bta .h4{
  margin-left: 190px;
}
  

.course-box4:hover{
  background-color:slateblue;
} 

/* .content{
  border-bottom: solid lightblue;
} */




}