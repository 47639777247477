*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.main{
    height: 60px;
}
.navbar {
display: flex;
align-items: center;
padding:10px;
background-color: #fff;
margin-left:35% ;
margin-top:5px ;
}

.navbar-brand{
    display: flex;
    position: absolute;
    align-items: center;

}

/*.navbar-search{
    display: flex;
    align-items: center;
}

.navbar-search input[type="text"]{
    padding: 10px;
    border: 1px solid #ccc;

}*/



.navbar-menu{
    display: flex;
    list-style: none;
    margin-top: 10px;
    margin-left: 50px;
}
.navbar-menu-item{
    margin-right: 15px;
    list-style: none;
    padding-left: 30px;

  
}
.navbar-menu-item a{
    text-decoration: none;
    color: #333;
    font-weight: bold;
    transition: color 0.3s ease;
   

}

.navbar-menu-item a:hover{
    color:#ff0000
}

.navbar-menu-item a button{
    padding: 10px 15px;
    background-color: #ff0000;
    color: #fff;
    border: none;
    cursor: pointer;
}


.nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: #ff0000;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

.languages{
  
}


@media only screen and (max-width:1024px) {

    .navbar-search{
        display: none;
    }

    .nav-btn{
        visibility: visible;
        opacity: 1;
        margin-left: 90%;
        margin-top: 10px;
        
    }

    .navbar{
        position: fixed;
        top: -2%;
        left: -35%;
        height: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
    z-index: 1;
      /*justify-content: center;*/
        text-align: left;
        gap: 0.5rem;
        background-color: white;
        transition: 1s;
       /* transform: translateY(-100vh); */
        transform: translateX(-110vw);
    }

    .navbar-menu{
        display: block;
        list-style: none;
        margin-top: 10%;
        margin-left: 0px;

    }
   .navbar-menu-item{
    margin-top: 20%;
    font-size: 20px;
   }
    .cart{
        color: #ff0000;
        position:fixed !important;
        top:20px ;
        white-space: nowrap;
        left: 80%;
    }

    .responsive_nav{
        transform: none;
    }

    .nav-close-btn{
   position: absolute;
   top: 0rem;
   right: 1rem;
    }
}