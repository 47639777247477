/* product detail */
.productdetail{
    display: flex;
    padding-top: 40px;
    background-color: aliceblue;
}

/* img-section */
.img-section{
    background-color: rgb(242, 65, 65);
    margin-left: 100px;
    margin-bottom: 20px;
    width: 400px;
    
}
.img-section h3{
    background-color: yellow;
    width: 50px;
    font-weight: bold;
    margin-bottom: 20px;
}

.productimg{
    height: 350px;
    padding: 20px;
    padding-left: 80px;

}


/* detail-section */
.detail-section{
    margin-left: 40px;
}

.detail-section .price{
    font-size: 15px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.price strike{
    color: gray;
}
.price span{
    opacity: 0.7;
}

.detail-section a{
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.7;
}

.detail-section button{
    background-color: rgb(244, 244, 12);
    border-radius: 6px;
    text-align: center;
    width: 170px;
    font-size: 15px;
    font-weight: bold;
    
    height: 50px;
    border: rgb(190, 190, 11);
    cursor: pointer;

}
.detail-section p{
    margin-right: 4px;
    color: black;
    opacity: 0.6;
}


/* media query for product details */
@media only screen and (max-width: 768px) {

    .productdetail{
        display: block;
        margin-left: 0px;
    }

    .img-section{
        background-color: rgb(242, 65, 65);
        margin-left: 40px;
        margin-bottom: 20px;
        width: 300px;
        
    }

    .productimg{
        height: 350px; 
        padding-left: 30px;
        padding-bottom: 30px;
        width: 300px;
  
    }

    /* detail-section */
    .detail-section{
        margin-left: 0px;
    }
    .detail-section h2{
        font-size: 20px;
        text-align: center;
    }
    .detail-section p{
       
        margin-left:25px ;
    }
    .detail-section a{
       
        margin-left:25px ;
    }
    .detail-section button{
       width: 340px;
        margin-left:20px ;
    }
    .detail-section .price{
       
        margin-left:25px;
        font-size: 25px;
    }
    .price span{
        color: rgb(179, 190, 18);
    }

}



/* styking the description section */

.description{
    margin-left: 90px;
}